<template>
  <div class="reservation-status-update">
    <div class="reservation-status-update--content">
      <p>Current Status:</p>
      <CRTextField
        style="margin-bottom: 10px"
        :value="reservationStatusLabel"
        disabled
      />
      <v-layout column>
        <v-form ref="status-form">
          <div>
            <v-flex xs12 d-flex>
              <CRSelect
                v-model="reservationStatus"
                label="New Status"
                :items="reservationStatusMap"
                validate-on-blur
                :rules="[
                  isRequired(true, isNotEmpty, {
                    req: 'New Reservation Status Is Required',
                    error: 'Reservation Status Is Required',
                  }),
                ]"
                placeholder="Select Status"
              />
            </v-flex>
          </div>
        </v-form>
      </v-layout>
    </div>
    <div class="cr-sidebar-dialog--btn-spacer"></div>
    <div class="cr-sidebar-dialog--action-btn" @click="submitForm">
      <span v-if="!loading">Update Status</span>
      <CRProgressCircular v-else :size="27" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isRequired, isNotEmpty } from '@/utils/validators'
import { authComputed } from '@/state/helpers'
import { EventBus } from '@/utils/event-bus'

export default {
  props: {},
  data() {
    return {
      isRequired,
      isNotEmpty,
      notes: '',
      loading: false,
      isDisabled: true,
      reservationIdToUpdate: null,
      reservationStatus: null,
      reservationStatusMap: [
        { text: 'Upcoming', value: 'upcoming' },
        { text: 'Started', value: 'started' },
        { text: 'Finished', value: 'finished' },
        { text: 'Hold', value: 'hold' },
      ],
      updateReservationStatusMessage: '',
    }
  },
  computed: {
    ...authComputed,
    ...mapState('reservations', {
      reservationsCache: (state) => {
        return state.reservationsCache
      },
    }),
    reservationId() {
      return this.$route.params.id
    },
    reservation() {
      return this.reservationsCache[this.reservationId]
    },
    reservationStatusLabel() {
      return (
        this.reservation.reservationStatus.charAt(0).toUpperCase() +
        this.reservation.reservationStatus.slice(1)
      )
    },
  },
  methods: {
    close() {
      this.$store.dispatch('app/closeDialog') // TODO: make this vuex's concern. Put in 'reservations'.
    },
    async submitForm() {
      this.loading = true
      if (!this.$refs['status-form'].validate()) {
        this.loading = false
        return
      }

      let note = ''
      if (this.reservation.reservationStatus === 'hold') {
        note = 'REMOVING HOLD'
      } else if (this.reservationStatus === 'hold') {
        note = 'PLACING ON HOLD'
      }

      this.$store.dispatch('reservations/addReservationComment', {
        reservation: {
          id: this.reservation.reservationId,
        },
        note,
      })

      await this.$store.dispatch('reservations/updateReservationStatus', {
        reservationId: this.reservation.reservationId,
        reservationStatus: this.reservationStatus,
        userId: this.currentUser.userId,
      })

      EventBus.$emit('refresh-detail')

      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.reservation-status-update {
  height: 100%;
  width: 500px;

  &--content {
    margin: 20px 48px;

    p {
      margin: 0 0 4px 0;
    }
  }
}
</style>
